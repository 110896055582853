.font-mont {
  font-family: "Montserrat", cursive;
}

a {
  text-decoration: unset;
}

a:hover {
  text-decoration: unset;
}

p {
  font-family: "Poppins", cursive;
  font-size: 18px;
  line-height: 150%;
}

b {
  font-family: "Poppins", cursive;
  font-weight: 800;
}

.assettitle {
  font-family: "Montserrat";
  font-weight: 700;
  margin-top: 1rem;
}

.toph1 {
  margin-top: 1em;
  font-size: 32px;
  margin-bottom: 0.6em;
  font-weight: 800;
}

.outsideurl {
  color: #1f1f1f;
  font-size: 18px;
  font-weight: 600;
}

a {
  color: #1f1f1f;
  font-size: 22px;
  font-weight: 400;
}

.assetdescription {
  margin-bottom: 4rem;
}

.carousel .carousel-control-next-icon {
  background-image: url(../Components/next.png);
}

.carousel .carousel-control-prev-icon {
  background-image: url(../Components/prev.png);
}

#leftmenu .active {
  font-family: "Poppins", cursive;
  font-weight: 600;
}

.navbar {
  font-size: 22px;
}

.exampleimage {
  margin-bottom: 20px;
}

.btnrightmargin {
  margin-right: 10px;
}

.myvideo {
  width: 853px;
  height: 480px;
}
